<template>
    <div style="width: 100%">
        <div class="container">
            <page-header-image />.
            <div style="margin-top: 40px"></div>
            <index-title-text :text="consultText" :link="link" @click.native="resetText"/>
            <div style="margin-top: 20px"></div>
            <div class="consultContainer">
                <div class="consultItem" v-for="(item, index) in consultItems" :key="item" :class="{'dif': index %2 != 0}" @click="toNews(index + 1)" v-show="$route.meta.isConsultTurn">
                    <img src="../static/img/Vector.png" alt="">
                    <p class="content">{{item.title}}</p>
                    <p class="time">{{item.time}}</p>
                </div>
                <router-view></router-view>
            </div>
        </div>
        <!-- <float-box /> -->
        <!-- <index-footer /> -->
    </div>
</template>
<script>
import FloatBox from '../components/floatBox.vue';
import IndexFooter from '../components/IndexFooter.vue';
import IndexTitleText from '../components/IndexTitleText.vue';
import PageHeaderImage from '../components/PageHeaderImage.vue';

export default {
  components: {
    PageHeaderImage, FloatBox, IndexFooter, IndexTitleText,
  },
  data() {
    return {
      consultText: '新闻公告',
      link: '/consult',
      consultItems: [
          {
              title: "开工大吉",
              time: "2022-02-08   16:54",
          },
          {
              title: "2022新春快乐、万事如意",
              time: "2022-01-27   09:30",
          },
          {
              title: "赏秋赏月享祝愿",
              time: "2021-09-19   11:38",
          },
          {
              title: "粽子飘香，粽情绵长",
              time: "2021-06-11   13:50",
          },
          {
              title: "创新工业园日新月异，宝德仕职工中秋发福利啦",
              time: "2020-09-28   20:53",
          },
          {
              title: "宝德仕祝您端午安康！",
              time: "2022-06-03   23:44",
          },
      ],
    };
  },
  methods: {
      toNews(num){
          let path = '/consult/news' + num;
          this.$router.push(path);
          this.consultText = '返回';
      },
      resetText() {
          this.consultText == '返回' ? this.consultText = '新闻公告' : '';
      },
      
  },
};
</script>
<style scoped>
    *{
        padding: 0;
        box-sizing: border-box;
        margin: 0;
    }

    .container{
        width: 100%
    }

    .consultContainer{
        position: relative;
        left: 5%;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 100px;
    }

    .consultItem{
        position: relative;
        width: 100%;
        height: 70px;
        background: rgba(4, 29, 114, 0.4);
        margin-top: 10px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 48px;
        color: #ffffff;
        display: grid;
        grid-template-columns: 5fr 75fr 20fr;
        grid-template-rows: 100%;
        grid-column-gap: 15px;
        cursor: pointer;
    }

    .consultItem > img{
        position: relative;
        height: 50px;
        left: 30%;
        top: 14%;
    }

    .content{
        position: relative;
        top: 15%;
    }

    .time{
        position: relative;
        right: 0%;
        top: 15%;
        font-size: 28px;
    }

    .dif{
        background: rgba(31, 137, 235, 0.4);
    }

    @media screen and (max-width: 1250px) {
        .time{
            font-size: 20px;
            right: 30%;
        }

        .consultItem > img{
            height: 40px;
            top: 23%;
        }

        .consultItem{
            font-size: 24px;
            height: 60px;
        }
    }

    @media screen and (max-width: 1000px) {
        .time{
            font-size: 16px;
            right: 30%;
            top: 8%;
        }

        .content{
            top: 8%;
        }

        .consultItem > img{
            height: 35px;
        }

        .consultItem{
            font-size: 20px;
            height: 55px;
        }
    }

    @media screen and (max-width: 630px){
        .time{
            font-size: 12px;
            right: 0%;
        }

        .content{
            top: 0%;
        }

        .consultItem > img{
            height: 30px;
        }

        .consultItem {
            position: relative;
            height: 50px;
            margin-top: 10px;
            font-size: 16px;
            line-height: 50px;
            color: #ffffff;
            display: grid;
            grid-template-columns: 5fr 70fr 25fr;
            grid-template-rows: 100%;
            grid-column-gap: 15px;
        }
    }
</style>
