<template>
  <router-link v-bind:to="linkTo">
    <div class="TitleText">
      {{ text }}

      <a href="">
        <img
          class="vector"
          src="../static/img/../../static/img/Vector.png"
          alt=""
        />
      </a>
    </div>
  </router-link>
</template>
<script>
export default {
  props: ['text', 'link'],
  data() {
    return {
      linkTo: this.link,
    };
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

/*字大标题*/
.TitleText {
  position: relative;
  width: 400px;
  height: 65px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 65px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #0e0e0e;
  padding-left: 10px;
  margin-left: 50px;
  z-index: 0;
  transition: all 0.5s;
  text-decoration: none;
}

.vector {
  display: none;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
}

.TitleText:hover .vector {
  display: block;
}

.TitleText::before {
  content: "";
  position: absolute;
  left: -10px;
  height: 100%;
  width: 10px;
  background: #1f89eb;
  z-index: -1;
  transition: all 0.5s;
}

.TitleText:hover {
  color: white;
  transition: all 0.5s;
  z-index: 0;
}

.TitleText:hover::before {
  content: "";
  height: 100%;
  width: 100%;
  background: #1f89eb;
  transition: all 0.5s;
  z-index: -1;
}

@media screen and (max-width: 800px) {
  .TitleText {
    width: 250px;
    margin-left: 25px;
  }
}

@media screen and (max-width: 650px) {
  .TitleText {
    width: 150px;
    margin-left: 25px;
    height: 50px;
    font-size: 20px;
  }
  .vector {
  right: 20px;
  height: 25px;
}
}
</style>
